//
// Pagination
// --------------------------------------------------


.pagination {
  --#{$prefix}pagination-font-weight: #{$pagination-font-weight};
  --#{$prefix}pagination-active-box-shadow: #{$pagination-active-box-shadow};

  margin-bottom: 0;
}

.page-link {
  display: flex;
  align-items: center;
  height: 100%;
  @include border-radius(var(--#{$prefix}pagination-border-radius));
  font-weight: var(--#{$prefix}pagination-font-weight);
  > i {
    margin-top: .0625rem;
    font-size: 1.425em;
  }
}

.page-item {
  margin: 0 $pagination-margin;
  &:first-child { margin-left: 0; }
  &:last-child { margin-right: 0; }
  &.active .page-link {
    box-shadow: var(--#{$prefix}pagination-active-box-shadow);
  }
}

.pagination-sm {
  --#{$prefix}pagination-border-radius: #{$pagination-border-radius-sm};
  --#{$prefix}pagination-font-size: #{$pagination-font-size-sm};
}

.pagination-lg {
  --#{$prefix}pagination-border-radius: #{$pagination-border-radius-lg};
  --#{$prefix}pagination-font-size: #{$pagination-font-size-lg};
}
