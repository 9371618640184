// 
// user.scss
// Use this to write your custom SCSS
//

.mb40 {
    margin-bottom: 40px;
}

.parallax-layer>img {
    border-radius: 18px;
    border: 3px solid #ccc;
    box-shadow: 6px 6px 16px 8px #00000018;
}
.parallax-layer>img {
    position: relative;
}
.parallax-layer>img#dash0 {
    top: 12%;
    margin-left: 30px;
    max-height: 757px;
    transform: scaleY(0.8);
    width: 960px;
}
.parallax-layer>img#dash1 {
    top: -1150px;
    left: -37%;
    scale: .21;
    transform: scaleX(1.02);
    border: none;
    border-radius: 60px;
    transition: all 0.3s ease-in-out;
    // filter: drop-shadow(44px 44px 34px #00000080);
}
.parallax-layer>img#dash2 {
    top: -40%;
    scale: .52;
    left: 0%;
    border: none;
    box-shadow: none;
    animation: fade_right 1s cubic-bezier(0.175, 0.885, 0.32, 1.3) 0.6s forwards;
    // filter: drop-shadow(24px 24px 20px #00000016);
}
.parallax-layer>img#dash3 {
    top: -8.2%;
    scale: .12;
    left: -13.8%;
    border: none;
    box-shadow: 4px 4px 5px 6px #00000020;
    // filter: drop-shadow(2px 4px 6px #00000050);
    border-radius: 4%;
    opacity: 0;
    animation: fade_right 1s cubic-bezier(0.175, 0.885, 0.32, 1.3) 1.2s forwards; // bounce_ma 8s cubic-bezier(0.175, 0.885, 0.32, 1.3) infinite
}
@keyframes bounce_ma {
    0% {
        transform: translateY(0px);
    }
    10% {
        transform: translateY(18px) hue-rotate(8deg) saturate(1.01);
    }
    20% {
        transform: translateY(0px);
    }
    40% {
        transform: translateY(34px) hue-rotate(12deg) saturate(1.02);
        scale: .141;
    }
    60% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes fade_right {
    from {
      transform: translateX(100px);
      opacity: 0.2;
      scale: 0.8;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
}
.parallax-layer>img#dash4 {
    top: -13.5%;
    scale: .112;
    left: 0%;
    border: none;
    box-shadow: 4px 4px 5px 6px #00000020;
    // filter: drop-shadow(2px 4px 6px #00000050);
    border-radius: 4%;
    opacity: 0;
    animation: fade_right 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.3) 1.6s forwards;
}
.parallax-layer>img#dash5 {
    top: -10%;
    scale: .112;
    left: 14%;
    border: none;
    box-shadow: 4px 4px 5px 6px #00000020;
    // filter: drop-shadow(2px 4px 6px #00000050);
    border-radius: 4%;
    opacity: 0;
    animation: fade_right 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.3) 2s forwards;
}

.nav-anim div.logo-anim {
    animation: fade_left 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.5) 0.3s forwards 1;
    opacity: 0;
    align-self: baseline;
}
@keyframes fade_left {
    from {
      transform: translateX(-140px);
      scale: 0.9;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
      scale: 1;
    }
}
@keyframes fade_up {
    from {
      transform: translateY(80px);
      scale: 0.9;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
      scale: 1;
    }
}
@for $i from 1 through 4 {
    .nav-anim div.top-nav-spec ul>li.nav-item.dropdown:nth-child(#{$i}n) {
        animation: fade_left 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.2) #{$i * 0.1 + 0.6}s forwards 1;
        opacity: 0;
        // cursor: pointer;
    }
}
// @for $i from 1 through 20 {
//     .nav-anim div.top-nav-spec ul>li:nth-child(#{$i}n) {
//         animation: fade_up 0.28s cubic-bezier(0.175, 0.885, 0.32, 1.04) #{$i * 0.04}s forwards 1;
//         opacity: 0;
//     }
// }

.nav-anim div.top-nav-spec ul>li.nav-item.dropdown > a.nav-link.dropdown-toggle {
    transition: all ease-in-out 0.2s !important;
}
.nav-anim div.top-nav-spec ul>li.nav-item.dropdown:hover > a.nav-link.dropdown-toggle {
    cursor: pointer;
    font-size: 110%;
    // scale: 1.1;
    background: aliceblue;
    border-radius: 8px;
    transform: translateY(-2px);
    font-weight: 700;
    color: #418e8f;
    // box-shadow: 0 0 10px 0px #c0c0c033;
    transition: all ease-in-out 0.2s !important;
}
.nav-anim div.top-nav-spec ul>li.nav-item.dropdown:hover a.nav-link.dropdown-toggle > i.std-icon {
    animation: bounce 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.2) 0.1s forwards 1;
}
@keyframes bounce {
    0% {
        transform: translateY(0px);
    }
    40% {
        transform: translateY(-2px); // rotateY(180deg)
        // scale: 1.8;
    }
    90% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}

.spec-logo-hov>img {
    padding: 4px 4px 4px 8px;
}
.spec-logo-hov {
    position: relative;
    border-radius: 6px;
    transition: all 0.3s ease-in-out !important;
    background: linear-gradient(155deg,#fafffed9,#ffffff,#fafffead);
    background-size: 280% 280%;
    animation: animated_login_logo 3.8s infinite !important;
}
.spec-logo-hov:hover {
    box-shadow: 4px 4px 4px 1px #00000010;
    transition: all 0.3s !important;
    scale: 1.02;
    filter: drop-shadow(-4px 4px 8px #00000030);
    border-radius: 8px;
    background: linear-gradient(155deg, #eaeaeaed, #ffffff, #efefefdb);
    background-size: 320% 320%;
    animation: animated_login_logo 3s infinite !important;
}
.align-nav-top {
    align-self: flex-start;
    margin-top: 30px;
}
.align-nav-top-login {
    align-self: center;
}

@keyframes animated_login_logo {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}
@keyframes scale_anim {
    0% {
      opacity: 0.9;
      filter: brightness(0.96) saturate(1.04) hue-rotate(6deg);
    }
    35% {
      transform: translateY(2px);
      opacity: 1;
      filter: brightness(0.98) saturate(1.08);
    }
    70% {
        filter: brightness(1.02);
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
      filter: brightness(1) saturate(1);
    }
}

.form-check.form-switch, .form-check.form-switch > * {
    transition: all 0.1s !important;
    align-items: center;
}

.form-check.form-switch:hover {
    transition: all 0.1s !important;
    cursor: pointer;
    filter: hue-rotate(30deg) drop-shadow(2px 4px 6px #00000028);
    border-radius: 12px;
    // padding: 8px 10px;
}
.form-check.form-switch:hover > * {
    cursor: pointer;
    transition: all 0.1s;
}
.form-check.form-switch:hover > label {
    font-weight: 800;
    transition: all 0.1s;
}

.log-in-btn {
    transition: all 0.3s ease-in-out !important;
    // background: linear-gradient(155deg,#e5f1efd9,#ffffff,#fafffead);
    // background-size: 280% 280%;
    // animation: animated_login_logo 3.8s infinite, fade_up 0.4s forwards cubic-bezier(0.175, 0.885, 0.32, 1.2);
    border-radius: 6px;
    padding: 8px 10px;
    // box-shadow: inset 0 0 10px 1px #a1a1a173, inset -6px -4px 8px 4px #f7f7f7e8;
    margin-top: 14px;
    color: ($primary);
    border-color: #bcd1e4;
    box-shadow: inset 0px 0px 12px -2px #bcc9d947;
    &:hover {
        transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4) !important;
        box-shadow: inset 0px 0px 12px -2px #bcc9d947, 6px 6px 6px 1px #3d4c4c4b;
        color: ($primary);
        border-color: #a0d5d8;
        background: linear-gradient(155deg,#e5f1efd9,#ffffff,#fafffead);
        background-size: 280% 280%;
    }
}
@keyframes fade_up {
    from {
      transform: translateY(40px);
      opacity: 0.2;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
}

.font112 {
    font-size: 112%;
}
.font118-login {
    font-size: 118%;
    font-weight: 800;
}
.accordion#accordionDefault {
    min-width: 300px;
    border: solid 2px #ccc;
    box-shadow: 2px 2px 3px 1px #00000020;
    border-radius: 8px !important;
    transition: all 0.3s ease-in-out !important;
}
.accordion#accordionDefault > *, .accordion#accordionDefault h3 {
    border-radius: 8px !important;
    transition: all 0.3s ease-in-out !important;
}

.accordion#accordionDefault:focus,
.accordion#accordionDefault:visited {
    box-shadow: 2px 2px 3px 1px #00000040 !important;
    transition: all 0.2s ease-in-out !important;
    border: solid 2px ($primary);
    border-radius: 8px !important;
    filter: brightness(0.98);
    color: ($primary);
}
.accordion#accordionDefault:hover {
    border: solid 2px ($primary);
    border-radius: 8px !important;
    box-shadow: 2px 2px 3px 1px #00000040;
    filter: brightness(0.96);
    transition: all 0.1s ease-in-out !important;
    color: ($primary);
}

.accordion#accordionDefault:hover button>i,
.accordion#accordionDefault:focus i,
.accordion#accordionDefault:visited i,
.accordion#accordionDefault div.accordion-collapse.collapse.show div>i {
    transition: all 0.3s ease-in-out !important;
    scale: 2.2;
    color: #dba50eec;
}

i.icon-anim {
    scale: 2;
    margin-right: 18px;
    color: #9397ad;
    animation: icon_anim 16s cubic-bezier(0.175, 0.885, 0.32, 1.5) 1s infinite;
}
i.icon-anim-req {
    scale: 2;
    margin-right: 18px;
    color: #fff !important;
    animation: icon_anim_req 22s cubic-bezier(0.175, 0.885, 0.32, 1.5) 0.4s infinite;
}
@keyframes icon_anim {
    0% {
        transform: rotateY(0deg);
        color: #467976;
        scale: 2;
    }
    70% {
        transform: rotateY(0deg);
        color: #2c5a57;
        scale: 2;
    }
    80% {
        transform: rotateY(320deg);
        scale: 2.6;
        // color: #dba50eec;
        color: #206412ec;
        opacity: 0.7;
    }
    90% {
        transform: rotateY(0deg);
        scale: 2;
        color: #33807c;
        opacity: 0.8;
    }
    100% {
        transform: rotateY(0deg);
        color: #467976;
        scale: 2;
    }
}
@keyframes icon_anim_req {
    0% {
        transform: rotateY(0deg);
        color: #467976;
        scale: 2;
    }
    60% {
        transform: rotateY(0deg);
        color: #2c5a57;
        scale: 2;
    }
    70% {
        transform: rotateY(300deg);
        scale: 2.6;
        // color: #488580ec;
        // color: #dba50eec;
        color: #206412ec;
        opacity: 0.7;
    }
    80% {
        transform: rotateY(0deg);
        scale: 2;
        color: #33807c;
        opacity: 0.8;
    }
    100% {
        transform: rotateY(0deg);
        color: #467976;
        scale: 2;
    }
}
.ml-2 {
    margin-left: 0.9rem !important;
}
.mr-3 {
    margin-right: 1.2rem !important;
}

.top-nav-spec {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 3%;
}

li.pharm-acc#regis {
    top: -16px !important;
    position: relative !important;
    animation: fade_right 1s 0.6s forwards;
    opacity: 0;
    border-radius: 8px !important;
    left: 30px;
    &:last-child {
        top: -2px !important;
        margin-left: 20px;
        & a {
            height: 46px;
            border: 2px solid #558689;
            background: #5d9497;
            &:hover {
                border: 2px solid #2a5659;
                background: #407275;
                transition: all 0.2s;
            }
        }
    }
    & a {
        border-radius: 4px !important;
    }
}

@keyframes fade_right {
    from {
      transform: translateX(60%);
      opacity: 0;
    }
    to {
      transform: translateX(0px);
      opacity: 1;
    }
}

.custom-nav-header {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
      max-width: 1360px;
    }
}

@media (max-width: 1550px) {
    img#dash0 {
        margin-left: -30px !important;
        max-height: 730px !important;
        width: 920px !important;
        left: -170px !important;
    }
    img#dash1 {
        display: none;
    }
    img#dash2 {
        // top: -38% !important;
        left: 0% !important;
    }
    img#dash3 {
        left: 38% !important;
    }
    img#dash4 {
        left: 22.5% !important;
    }
    img#dash5 {
        left: 6% !important;
    }
    .dash-rt {
        left: -48% !important;
        width: 50% !important;
    }
}
@media (max-width: 1300px) {
    img#dash0 {
        margin-left: -50px !important;
        max-height: 730px !important;
        width: 920px !important;
        left: -240px !important;
    }
    img#dash1 {
        display: none;
    }
    img#dash2 {
        // top: -38% !important;
        left: 0% !important;
    }
}
@media (max-width: 1200px) {
    img#dash0 {
        margin-left: -50px !important;
        max-height: 730px !important;
        width: 920px !important;
        left: -280px !important;
    }
    img#dash2 {
        // top: -32% !important;
        left: 0% !important;
    }
}
@media (max-width: 1100px) {
    img#dash0, img#dash1, img#dash2, img#dash3, img#dash4, img#dash5 {
        display: none !important;
    }
    .dash-rt {
        left: -94% !important;
        width: 100% !important;
    }
    img.down-sz {
        scale: 0.9 !important;
    }
    img.fillware-pic {
        display: none;
    }
    span.fill-title {
        left: 0px !important;
    }
}
@media (max-width: 991px) {
    .dash-lf {
        display: none;
    }
    .dash-rt {
        left: 0% !important;
        width: 100% !important;
        top: 46px;
    }
}
@media (min-width: 991px) and (max-width: 1320px) {
    .spec-logo-hov {
        scale: 0.7;
        margin-left: -60px;
    }
    li.pharm-acc#regis {
        scale: 0.8;
        left: 2px;
        top: -14px !important;
    }
}
@media (min-width: 1234px) and (max-width: 1320px) {
    ul.navbar-nav.me-auto.mb-2.mb-lg-0.ml-2 {
        position: relative;
        scale: .8;
    }
}
@media (min-width: 991px) and (max-width: 1233px) {
    ul.navbar-nav.me-auto.mb-2.mb-lg-0.ml-2 {
        left: 220px;
        position: relative;
        scale: .8;
        top: -108px;
    }
}
@media (max-width: 991px) {
    li.pharm-acc#regis {
        left: -40px;
        top: 12px !important;
        scale: 0.8;
        &:last-child {
            top: 12px !important;
            margin-left: unset;
        }
    }
    .spec-logo-hov {
        margin-left: 20px;
    }
    .offcanvas.offcanvas-end {
        border-radius: 16px;
        height: fit-content;
        min-height: 480px;
        border: 3px solid #d1d1d1;
    }
    button.navbar-toggler {
        margin-right: 30px;
    }
}
@media (max-width: 700px) {
    .spec-logo-hov {
        // box-shadow: 4px 4px 2px 0px rgba(61,76,76,.2941176471), inset 0 0 10px 1px rgba(161,161,161,.4509803922), inset -6px -4px 8px 4px rgba(247,247,247,.9098039216);
        box-shadow: none;
        transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.4) !important;
        border-radius: 8px;
        // scale: 0.7;
        // margin-left: -50px;
        max-width: 350px;
    }
    div.container.mr-ml-head.nav-anim {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
    .navbar-brand.pe-3.spec-logo-hov:hover {
        box-shadow: 4px 4px 4px 1px #00000014, inset 0 0 10px 1px #00000008, inset -2px -2px 8px 4px #f7f7f7e8 !important;
        transition: all 0.3s !important;
        scale: 0.75 !important;
    }
}

.acc-btn {
    border-bottom: none !important;
    border-radius: 6px !important;
    background: linear-gradient(155deg,#e9efef,#fffffff1,#e9efef);
    background-size: 310% 310%;
    animation: animated_login_logo 2.8s infinite;
    color: #000 !important;
}

.security-img {
    // scale: 1.4;
    opacity: 0.8;
    color: #517883;
}

i.std-icon {
    scale: 1.6;
    margin-right: 18px;
}
i.pend-icon {
    scale: 1.4;
    opacity: 0.7;
    color: #b5b5b5;
    margin-right: 18px;
    margin-left: 12px;
}
i.bx-check {
    // color: #2c5a57;
    color: #447546;
    scale: 1.8;
    margin-right: 18px;
    margin-left: 12px;
}
i.bx.bx-loader-alt.pend-icon {
    animation: pending_spinner 5s linear infinite;
}
@keyframes pending_spinner {
    0% {
        transform: rotate(0deg);
        scale: 1.4;
    }
    50% {
        transform: rotate(180deg);
        scale: 1.6;
    }
    100% {
        transform: rotate(360deg);
        scale: 1.4;
    }
}
ul>li>i.bx-check+span {
    color: #305e32;
    font-weight: 600;
    font-size: 90%;
}
ul>li>i.bx-loader-alt+span {
    // color: #c79800;
    // color: #085e64;
    color: #7c7c7c;
    font-weight: 600;
    font-size: 90%;
}

li.nav-item.dropdown {
    margin-right: 14px !important;
}

div.dd-btn:hover {
    cursor: pointer;
    // filter: brightness(0.96);
    box-shadow: inset 0px 0px 20px 2px #00000020, 0px 0px 10px 1px #00000010;
    background: linear-gradient(120deg,#ecf3f3,#fffffffb,#ecf3f3);
    background-size: 380% 380%;
    animation: animated_login_logo 3.2s infinite;
    color: $primary;
    text-shadow: 0px 0px 1px #00000010;
    border-radius: 5px !important;
    border: 2px solid #447546;
}

ul.list-drop>li, .other-li {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none !important;
    overflow-wrap: break-word;
    // flex-wrap: wrap;
}

.min-wd-ht {
    max-height: 1000px !important;
    max-width: 1000px !important;
    width: fit-content !important;
    height: fit-content !important;
}
.wd-ht-100 {
    width: 100% !important;
    height: 100% !important;
}
.mt-minus {
    margin-top: -12px;
}
.mt-20 {
    margin-top: 20px;
}

.dropdown-menu {
    height: fit-content !important;
    cursor: unset;
}
.dropdown-menu:hover {
    cursor: default;
}

.custom-nav-dd {
    height: fit-content !important;
    min-height: 717px;
    border: 4px solid #ccc !important;
    border-radius: 10px;
}

.custom-nav-one-r {
    height: fit-content !important;
    border: 4px solid #ccc !important;
    border-radius: 10px;
}

ul>h6.px-3.mb-2 {
    margin-left: -12px !important;
}

.col-custom {
    margin: -1px;
    background-color: #e5eff1c4;
    overflow: hidden;
}

a.a-hov-eff {
    padding: 12px 2px;
    transition: all 0.3s ease-in-out;
}
a.a-hov-eff:hover {
    color: #305e32;
    text-shadow: 0px 0px 1px #00000010;
    background: #f2f7f5c9;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}
a.a-hov-eff:hover i {
    color: #438f47;
    text-shadow: 0px 0px 1px #00000010;
    scale: 2.4;
    transition: all 0.3s ease-in-out;
}
a.a-hov-eff i {
    transition: all 0.3s ease-in-out;
}
a.a-hov-eff:hover span {
    color: #305e5a;
    text-shadow: 0px 0px 1px #00000010;
    // font-size: 96%;
}

.marg-top-200 {
    margin-top: 200px !important;
}

img.img-load {
    animation: img_fade_in 0.6s ease-in-out forwards 0.3s;
    opacity: 0;
    box-shadow: -3px 3px 10px 2px #00000060;
    border-radius: 10px;
}
@keyframes img_fade_in {
    from {
        opacity: 0;
        // transform: translateX(-200px);
        filter: blur(4px);
    }
    to {
        opacity: 0.94;
        // transform: translateX(0px);
        filter: blur(0px) brightness(0.92);
    }
}
img.img-load.delay-1 {
    animation-delay: 0.6s !important;
}

.scale6 {
    scale: 0.6;
}
.scale8 {
    scale: 0.8;
}
.scale9 {
    scale: 0.9;
}
.scale12 {
    scale: 1.2;
}

.font6 {
    font-size: 60% !important;
}
.font7 {
    font-size: 70% !important;
}

i.img-icon {
    scale: 1.7;
    margin: 14px;
    color: #7f7f7f !important;
    filter: opacity(0.6);
}

.footer-bottom {
    background: #c0cbcda6;
    overflow: hidden;
    margin-top: 120px !important;
}
a.nav-link.dropdown-toggle {
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}
a.nav-link.dropdown-toggle.active {
    // cursor: pointer;
    filter: drop-shadow(0px 0px 10px #00000020);
    scale: 1.14;
    // font-size: 104% !important;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

i.icon-dd {
    scale: 4;
}

.integ-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
}

img.mod-img {
    border-radius: 10px;
    border: 3px solid #8a8a8a;
    transition: all 0.3s ease-in-out;
    box-shadow: 3px 3px 10px 2px #00000060;
}
img.mod-img:hover {
    scale: 1.08;
    transform: perspective(1000px) rotateX(-2deg) rotateY(-2deg) translateX(20px) translateY(0px) translateZ(14px);
    box-shadow: 3px 3px 14px 2px #00000040;
    transition: all 0.3s ease-in-out;
}

img.mod-img-aw {
    border-radius: 6px;
    border: 2px solid #cacaca;
    transition: all 0.3s ease-in-out;
    box-shadow: 3px 3px 10px 2px #00000020;
}
img.mod-img-aw:hover {
    scale: 1.12;
    transform: perspective(1000px) rotateX(-2deg) rotateY(-2deg) translateY(-4px) translateY(0px) translateZ(14px);
    box-shadow: 3px 3px 14px 2px #00000040;
    transition: all 0.3s ease-in-out;
}
.mt-4 {
    margin-top: 40px !important;
}

.row-award {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-around;
    align-content: center;
}
.award-span {
    border-radius: 12px;
    padding: 12px 14px;
    background: linear-gradient(155deg, #fffaba, rgb(255 252 216 / 71%), #fffaba);
    background-size: 280% 280%;
    animation: animated_login_logo 3.8s infinite;
    margin-left: -2px;
    border: solid 2px #ffed6d;
    box-shadow: 2px 2px 6px 1px #00000010;
    font-weight: 600;
}
img.award-ic {
    width: fit-content;
    margin: 0px;
    align-self: self-start;
    position: relative;
    left: 0;
    margin-left: 10px !important;
}

.mb-200 {
    margin-bottom: 200px;
}
.margin-spec {
    margin: 60px 20px;
}

.dash-lf {
    position: relative;
    left: -10%;
}
.dash-rt {
    position: relative;
    left: -28%;
}

.feat-cont {
    background: #e6efeed9;
    margin-bottom: 30px;
    border: 2px solid #ccc;
    border-radius: 12px;
    padding-top: 34px;
}

.pt-ldfp {
    padding-top: 42px;
}

.mt140 {
    margin-top: 140px;
}

.pad-aw {
    padding: 10px 2px 44px 2px;
}

.pricing-cont {
    border: 3px solid #a0c1c3;
    border-radius: 6px;
    padding: 40px !important;
}

.fill-row-pr {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    height: min-content;
}
.fill-pr-desc {
    margin-left: 10px;
}
.fillware-pic {
    scale: 0.5;
    left: -65px;
    position: relative;
}

span.fill-title {
    position: relative;
    left: -105px;
}

@media (min-width: 1680px) {
    li.pharm-acc#regis {
    }
}

button.btn-hov {
    transition: all 0.2s ease-in-out;
    transform: perspective(60px) rotateX(0deg) rotateY(0deg) translateY(0px) translateZ(2px);
    transform-style: preserve-3d;
}
button.btn-hov:hover {
    font-size: 102% !important;
    transform: perspective(200px) rotateX(-2deg) rotateY(-2deg) translateY(1px) translateZ(14px);
    transform-style: preserve-3d;
    box-shadow: 3px 3px 14px 2px #00000040;
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.5);
}

.show-btn-menu {
    display: flex !important;
}

.mt-180 {
    margin-top: 180px !important;
}
.pb-90 {
    padding-bottom: 90px;
}
.img-bg-tilt {
    max-width: 60%;
}
.img-bg-tilt#dash0 {
    max-width: 68%;
    border-radius: 12px;
    top: -14px;
    position: relative;
    max-height: 100%;
    left: 182px;
    transform: scaleX(1.5);
    opacity: 0.8;
}
.img-bg-tilt#dash1 {
    max-width: 60%;
    // right: 850px;
    left: -26px;
    position: relative;
    border-radius: 12px;
}
.img-bg-tilt#dash2 {
    left: 278px;
    position: relative;
    max-width: 72%;
    border-radius: 12px;
    top: -8px;
}
.img-bg-tilt#dash3 {
    left: 762px;
    position: relative;
    max-width: 13%;
    top: 20%;
    transform: translateZ(30px);
    box-shadow: 8px 10px 18px 2px #00000020;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
}
// img.img-bg-tilt#dash3:hover, img.img-bg-tilt#dash4:hover, img.img-bg-tilt#dash5:hover {
//     box-shadow: 8px 10px 18px 2px #00000040;
//     transition: all 0.3s ease-in-out;
//     transform: translateZ(40px);
//     filter: hue-rotate(30deg) saturate(1.02);
//     cursor: pointer;
// }
// img.img-bg-tilt#dash3:active, img.img-bg-tilt#dash4:active, img.img-bg-tilt#dash5:active {
//     box-shadow: 8px 10px 18px 2px #00000060;
//     transition: all 0.1s ease-in-out;
//     transform: translateZ(-2px) translateY(4px);
//     filter: hue-rotate(50deg) saturate(1);
//     cursor: pointer;
// }
.img-bg-tilt#dash4 {
    left: 564px;
    position: relative;
    max-width: 12%;
    top: 20%;
    transform: translateZ(20px);
    box-shadow: 8px 10px 18px 2px #00000020;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
}
.img-bg-tilt#dash5 {
    left: 346px;
    position: relative;
    max-width: 13%;
    top: 20%;
    transform: translateZ(10px);
    box-shadow: 8px 10px 18px 2px #00000020;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.sec-gal {
    margin: auto;
    max-width: 1030px;
}

.mb-100 {
    margin-bottom: 100px;
}

@media (min-width: 1900px) {
    .col-xl-5#introText {
        flex: 0 0 auto;
        width: 46%;
    }
}

.card-hover:not(.bg-transparent) {   
    transition: all 0.3s !important;
    padding-top: 8px;
    padding-bottom: 8px;
    &:hover {
        box-shadow: 4px 4px 6px 2px #00000012;
        transform: translateY(-2px);
        border-radius: 8px;
        background: linear-gradient(155deg, #f8f8f8, #ffffff, #f8f8f8);
        background-size: 280% 280%;
        animation: animated_login_logo 3.2s infinite;
        filter: saturate(1.08);
        border: 2px solid #cacaca;
        transition: all 0.3s;
    }
}

div.container.mr-ml-head.nav-anim {
    flex-wrap: nowrap;
}